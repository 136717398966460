import { Component, OnInit } from '@angular/core';
import { AuthService, ValidationStatus } from './shared/services/AuthService';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from './shared/services/app-config.service';
import { environment } from 'src/environments/environment';
import { MiniLoaderService } from './shared/modules/mini-loader/mini-loader.service';
import { HelperService } from './shared/services/Helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
  //   //this.processData();
  // }

  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   if (window.location.pathname === '/') {
  //     this.authService.setValidationResult = ValidationStatus.Invalid;
  //   }
  // }

  loader: boolean;
  showStartPage: boolean;
  tokenHelper = new JwtHelperService();

  constructor(
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private appConfService: AppConfigService,
    public loaderService: MiniLoaderService,
    private helperService: HelperService
  ) {
    console.log('Version: 1.0.18');

    this.loader = false;
    this.showStartPage = true;

    this.getQueryParams();

    // this.authService.isTokenValid().then(
    //   (res) => {
    //     if (!res) {
    //       //this.authService.setValidationResult = ValidationStatus.Invalid;
    //     } else {
    //       this.authService.isAuthenticatedCognitoGroups().then((isAuth) => {
    //         if (!isAuth) {
    //           this.authService.signOut();
    //         } else {
    //           this.authService.setValidationResult = ValidationStatus.Valid;
    //         }
    //       });
    //       let urlLength = window.location.pathname.length;
    //       if (urlLength < 2) {
    //         this.router.navigate([environment.defaultPage]);
    //       }
    //     }
    //   },
    //   (err) => {
    //     this.authService.setValidationResult = ValidationStatus.Invalid;
    //     console.error(err);
    //   }
    // );
  }

  async ngOnInit(): Promise<void> {
    this.showStartPage = true;

    try {
      this.loaderService.loader = true;

      await this.appConfService.load();

      const tokenValidationResult = await this.authService.isTokenValid();

      if (!tokenValidationResult) {
        await this.authService.unautorized();
        return;
      }

      const userId = await this.authService.getUserId();

      if (!userId?.length) {
        await this.authService.unautorized();
        return;
      }

      const isAuth = await this.authService.isAuthenticatedCognitoGroups();

      if (!isAuth) {
        this.helperService.notify('User is not techadmin', 'ERROR');
        await this.authService.unautorized();
        return;
      }

      this.authService.validationResult = ValidationStatus.Valid;
      this.showStartPage = false;

      let urlLength = window.location.pathname.length;
      if (urlLength < 2) {
        this.router.navigate([environment.defaultPage]);
      }
    } catch (error) {
      await this.authService.unautorized();
      console.error(error);
    } finally {
      this.loaderService.loader = false;
    }
  }

  getQueryParams(): void {
    this.route.queryParams.subscribe((params) => {
      let email: string = params['email'] ?? '',
        code: string = params['code'] ?? '';

      if (email.indexOf(' ') > -1) {
        email = email.replace(/ /g, '+');
      }

      if (email?.length && code?.length) {
        this.showStartPage = false;
        this.router.navigate(['login'], {
          queryParams: {
            code: code,
            email: email,
          },
        });
      }
    });
  }

  checkAuthentication(): void {
    this.showStartPage = false;
    this.router.navigate(['login']);
  }

  // processData() {
  //   // store data into local storage before browser refresh
  //   sessionStorage.removeItem('Bay-Manager-User');
  // }
}
